import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { BaseResponse } from '../../models/http.model';


export enum HttpSuccessStatus {
  Ok = 200,
  NoContent = 204
}

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      map(event => this.handleSuccess(event)),
      catchError(event => this.handleError(event))
    );
  }

  protected handleSuccess(event: HttpEvent<any>) {
    if (event.type === HttpEventType.Response) {
      const isSuccess = event.status === HttpSuccessStatus.Ok && event.body?.status === 'success' 
      || event.status === HttpSuccessStatus.NoContent;

      const body: BaseResponse = { ...event.body, isSuccess };
      return event.clone({ body });
    }
    
    return event;
  }

  protected handleError(event: any) {
    const { error: {status, errors} } = event;
    const body: BaseResponse = {
      status,
      errors,
      hasErrors: errors?.length > 0
    };
    return of(new HttpResponse({ body }));
  }
}
